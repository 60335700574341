<template>
    <div class="wrapper-ficha noticia">

        <a @click="back" class="volver"><img src="/img/flecha_salir-azul.svg" alt=""></a>


        <div class="contenedor-evento" v-if="data">

            <div class="imagen-principal">
                <img :src="data.header_img.url" :alt="data.title">
            </div>


            <div class="contenedor-cuerpo">

                <div class="cuerpo-real">
                    <h2>{{data.title}}</h2>
                    <h4 class="js-fecha">{{data.created_at | date}}</h4>
                    <editorjs :modulos="data.description"></editorjs>

                </div>

            </div>
        </div>
        <div class="cover-slider cover-cerrar">

            <div class="contenedor-popup row-center">
                <div class="popup slider">
                    <a href="" target="_blank" download="" class="boton-descargar"><img
                            src="/AntiguosAlumni/img/descargar.svg" alt="Descargar imagen"></a>
                    <a onclick="cerrarpopup()" class="cerrar-popup material-icons">✕</a>
                    <div class="contenedor-slider js-slider">

                    </div>
                </div>
                <span onclick="cerrarpopup()" class="capa-cerrar-popup"></span>
            </div>

        </div>

        <noticias-recientes></noticias-recientes>
  

    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    import noticiasrecientes from '@/components/noticias-recientes';

    import router from '@/router';

    export default {
        name: 'noticia',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: ''
        }),
        methods: {
            ...mapActions(['requestEntrie']),
            setData() {
                this.requestEntrie(this.$route.params.id).then(r => {
                    this.data = r;
                })
            },
            back(){
                router.go(-1)
            }
        },
        components : {
            'noticias-recientes' : noticiasrecientes
        },
        filters: {
            urlToID(value) {

                let string;

                if (value.includes('youtube')) {
                    string = 'https://www.youtube.com/embed/' + value.split("?v=")[1];
                } else if (value.includes('vimeo')) {
                    string = 'https://player.vimeo.com/video/' + value.split("/").pop();
                }

                return string;
            }
        },
        watch : {
            '$route'(){
                this.setData();
            }
        }
    }
</script>